:root {
  --purple: #56208c;
  --gray: #939598;
  --purple-100: #f6f1fb;
  --black: #000;
  --soft-white: #f9f9f9;
  --white: #fff;
  --red: #bf2517;
  --green-fluo: #20ff87;
  --font-regular: 'Inter', sans-serif;
  --fs-xxs: 12px;
  --fs-xs: 14px;
  --fs-s: 16px;
  --fs-m: 24px;
  --fs-l: 32px;
  --fs-xl: 48px;
  --dim-xxs: 8px;
  --dim-xs: 14px;
  --dim-s: 16px;
  --dim-m: 24px;
  --dim-l: 32px;
  --dim-xl: 48px;
}

html {
  scroll-behavior: smooth;
}

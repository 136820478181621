._2ILZE {
  position: relative;
  width: 100%;
  height: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  background-color: #f3f3f3;
  flex-flow: column;
  align-items: stretch;
  box-sizing: border-box;
}

._1_Dg2 {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3000;
  width: 100%;
  height: 100%;
  background-color: #f3f3f3;
  transform: scale(1);
  transform-origin: top center;
  display: flex;
  flex-flow: column;
  align-items: stretch;
}

._2qwzr {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  flex: 0 1 auto;
}

._Pfcmb {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  touch-action: auto;
  flex: 0 1 auto;
}

._3q7r8 {
  background-color: var(--white);
}

[data-is-not-keyboard-user='true'] *:focus {
  outline: none;
}

._L8X8r {
  height: 100%;
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  transform: translateZ(0);
  transition-duration: 0s;
  transition-property: transform;
  transition-timing-function: linear;
  will-change: transform;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

._L8X8r._or-gG {
  flex-direction: row-reverse;
}

._FP5OY {
  cursor: -webkit-grab;
  cursor: grab;
}

.isGrabbing > ._FP5OY {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

._1eGao {
  height: 100%;
  min-width: 100%;
  width: 100%;
  max-width: 100%;
}

._3iVQ0 {
  position: relative;
  height: 100%;
  width: 100%;
  margin: 0;
  border: 0;
  border-image-width: 0;
  padding: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
}

._t1897 {
  background: #f3f3f3;
  background-color: #f3f3f3;
  position: relative;
  height: 100%;
  width: 100%;
  margin: 0;
  border: 0;
  border-image-width: 0;
  padding: 0;
  -o-object-fit: contain;
  object-fit: contain;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
}

._1BRif {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -o-object-fit: contain;
  object-fit: contain;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
}

._1BRif._3UdL5 {
  display: none;
}

._1BRif::before,
._t1897::before {
  background: var(--white);
  background-color: var(--white);
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  min-width: 100%;
  height: 100%;
  min-height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

._3lwW_ {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

._1zlZS {
  opacity: 0.25;
  pointer-events: none;
  cursor: not-allowed;
}

._1R7KP {
  height: 50px;
  padding: 0 10px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  cursor: text;
}

._1OkCh {
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  cursor: text;
}

.__JnHV {
  background: var(--white);
  font-weight: bold;
  padding: 5px;
  opacity: 1;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
}

._3lLfB {
  filter: drop-shadow(0px 0px 5px #888);
}

._14Fp5 {
  height: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

._14Fp5._3Rfma {
  flex-direction: row-reverse;
}

/*center positions*/
._1cqA3 {
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

._2GizQ {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

._2zEIf {
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

/*top positions*/
._lhmht {
  top: 0;
  left: 0;
}

._29p_Y {
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

._3r4Pe {
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

._1oKnM {
  top: 0;
  right: 0;
}

/*bottom positions*/
._2A4to {
  bottom: 0;
  left: 0;
}

._3apmu {
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

._2ljUm {
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

._3XvNX {
  bottom: 0;
  right: 0;
}

._ZTBlf {
  position: relative;
  margin: 0;
  border: none;
  padding: 0;
  display: inline-block;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  opacity: 1;
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: manipulation;
}

._3LX_O {
  filter: drop-shadow(0px 0px 3px #888);
}

._ZTBlf:hover {
  opacity: 1;
}

@media (hover: none) {
  ._ZTBlf:hover {
    opacity: 1;
  }
}
._lfOsC {
  display: flex;
  justify-content: center;
  align-items: center;
}

._1Pekn {
  width: 50px;
  height: 60px;
}

._dZ8C- {
  width: 50px;
  height: 50px;
}

._20GWq {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

._3WRGR {
  stroke: #bbb;
  stroke-opacity: 1;
  stroke-width: 1px;
  fill: var(--white);
}

._1JHpX {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  overflow-wrap: break-word;
  word-break: normal;
  white-space: normal;
  -webkit-user-select: auto;
  -moz-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
}

._XQjA1 {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-zoom: 10%;
  zoom: 10%;
  font-size: 10%;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  text-size-adjust: none;
  pointer-events: none;
}

._XQjA1 * {
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  text-size-adjust: none;
}

._XQjA1 > * {
  pointer-events: none;
}

/*Only for Chrome, Opera and Edge*/
@media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
  ._XQjA1 {
    font-size: 100%;
  }
}
/*Only for FireFox*/
@-moz-document url-prefix() {
  ._XQjA1 {
    font-size: 10%;
  }
}
._2c50p {
  flex: 0 0 10%;
  margin: 3px 0 0;
  padding: 0;
  overflow-y: hidden;
  overflow-x: scroll;
  cursor: -webkit-grab;
  cursor: grab;
  scrollbar-width: none;
  /* Prevent the default behaviour of "scroll chaining" where parent element
     gets scrolled when the child element is over scrolled,
     in order to prevent going to the previous or the next page. */
  -webkit-overflow-scrolling: touch;
  -ms-scroll-chaining: none;
  overscroll-behavior-x: contain;
  -ms-overflow-style: none;
}

.isGrabbing._2c50p {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

._2c50p::-webkit-scrollbar {
  display: none;
}

._-LJ2W {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

._-LJ2W._3NsOQ {
  flex-direction: row-reverse;
  /*thumbnails would be frozen (can not be scrolled) with justify-content: flex-start;*/
  justify-content: flex-end;
}

._-cAh3 {
  display: block;
  height: 100%;
  min-width: 10%;
  width: 10%;
  max-width: 10%;
  overflow: hidden;
  margin-right: 3px;
  padding: 0;
  opacity: 0.5;
  list-style: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
}

._19gvi {
  opacity: 1;
}

._-cAh3:not(._19gvi):hover {
  opacity: 1;
}

/* prevent thumbnail to stay in the hover state on touch devices,
   since there is no such thing as "hover" on touch devices */
@media (hover: none) {
  ._-cAh3:not(._19gvi):hover {
    opacity: 0.5;
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/* .side-bar {
  top: 0;
  left: 0;
  height: 100%;
  color: white;
  writing-mode: vertical-rl;
  text-orientation: sideways-right;
} */

/* .marquee p {
  overflow: hidden;
  white-space: nowrap;
  height: 100%;
} */

.marquee span {
  animation: marquee 8s linear infinite;
  /* display: inline-flex;
  padding-right: 10px;
  font-size: 4rem; */
}

@keyframes marquee {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0);
  }
}
